const url = "https://nodepune-12jyotirlingaunity.mobiloitte.io/api/v1";
// const url = "http://172.16.2.16:3045/api/v1";

const ApiConfig = {
  connectWallet: `${url}/user/connectWallet`,
  GetTemple: `${url}/user/getTemples`,
  enterMetavers: `${url}/user/enterTempleRoom`,
  leaveRoom:`${url}/user/leaveTempleRoom`,
};
export default ApiConfig;
