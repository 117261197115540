import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Dialog,
  Tooltip,
  Typography,
} from "@material-ui/core";
import SwipeableTemporaryDrawer from "./RightDrawer";
import Logo from "src/component/Logo";
import DialogContent from "@material-ui/core/DialogContent";
// import NotificationList from "src/views/pages/notifications/index";
import { useHistory, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import Badge from "@material-ui/core/Badge";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { AuthContext } from "src/context/Auth";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import { UserContext } from "src/context/User";
import LanguageIcon from "@material-ui/icons/Language";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SecurityIcon from "@material-ui/icons/Security";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#2A2A2B",
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    borderRadius: "25px",
    // background:
    //   "linear-gradient(144deg, #00ACEB 0%, #00B0ED -0.82%, #1069C2 70.35%, #1069C2 100%)",
    height: 266,
    background: "#004AAD",
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  iconbutton: {
    color: theme.palette.secondary.main,
  },
  search: {
    height: "50px",
    width: "100%",
    maxWidth: "376px",
    color: "#ABABAB",
    borderRadius: "100px",
    display: "flex",
    backgroundColor: "#302F35",
    alignItems: "center",
    justifyContent: "left",
    margin: "0px 5px",
    marginTop: "5px",
    marginLeft: "8px",
    "& input": {
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchInput: {
    color: "#ABABAB",
    cursor: "text",
    display: "inline-flex",
    position: "relative",
    fontSize: "14px",
    boxSizing: "border-box",
    alignItems: "center",
    fontWeight: 400,
    lineHeight: "1.1976em",
    width: "71%",
  },
  searchIcon: {
    fontSize: "16px",
    paddingLeft: "10px",
    color: "#fff",
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  NotificationListStyle: {
    background: theme.palette.background.Notification,
    color: theme.palette.text.NotificationColor,
  },
  navbarIcon: {
    display: "flex",
  },
  linkcolor: {
    color: "#fff",
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16.6667px",
    lineHeight: "29px",
  },
  iconColor: {
    "@media(max-width: 767px)": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [themeChange, seThemeChange] = useState("DARK");
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);
  const [unReadNotification, setUnReadNotification] = useState(0);

  const [open, setOpen] = useState(false);
  const themeSeeting = useContext(SettingsContext);

  //   const getViewMyProfile = async (values) => {
  //     const token = localStorage.getItem("token");
  // console.log("sachin")
  //     try {
  //       const res = await Axios({
  //         method: "GET",
  //         url: ApiConfig.viewMyProfile,
  //         headers: { token: token },
  //       });
  //       if (res.data.statusCode === 200) {
  //         // setProfile(res.data.result);
  //       } else {
  //         // toast.error(res.data.responseMessage);
  //       }
  //     } catch (error) {
  //       // console.log(error);

  //       if (
  //         error.response.data.responseMessage === "jwt malformed" 
  //       ) {
  //         history.push("/login");
  //         window.localStorage.clear();

  //         toast.error("jwt malformed")
  //       }
  //      else if (
  //         error.response.data.responseMessage === 
  //         "jwt expired"
  //       ) {
  //         history.push("/login");
  //         window.localStorage.clear();
  //         toast.error("Session  expired")
  //       }
  //     }
  //   };
  //   const token = localStorage.getItem("token");
  //   useEffect(() => {

  //     getViewMyProfile();
  //   }, [token]);
  // const readNotificationList = async () => {
  //   try {
  //     const res = await Axios({
  //       method: "GET",
  //       url: ApiConfig.readNotification,
  //       headers: {
  //         token: window.localStorage.getItem("token"),
  //       },
  //     });

  //     if (res.data.responseCode === 200) {
  //       setUnReadNotification(res?.data?.result?.unReadCount);
  //       user.NotificationDataList();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   readNotificationList();
  // }, []);

  const [count, setCount] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <>
      <Logo
        style={{
          paddingTop: "0px",

          cursor: "pointer",
          width: "112px",
          paddingLeft: "35px",
        }}
      />
      <Box flexGrow={1} />
      &nbsp;&nbsp;
      {/* <IconButton
        style={{
          marginRight: 70,

          color: "#fff",
        }}
        // onClick={() => setOpen(true)}
        // onClick={handleChange}
        className={classes.iconColor}
      >
        <Typography className={classes.linkcolor}>Tutorial</Typography>
      </IconButton>
      <IconButton
        style={{
          marginRight: 70,

          color: "#fff",
        }}
        className={classes.iconColor}
        // onClick={() => setOpen(true)}
        // onClick={handleChange}
      >
        <Typography className={classes.linkcolor}>Announcement</Typography>
      </IconButton>
      <IconButton
        style={{
          marginRight: 55,

          color: "#fff",
        }}
        className={classes.iconColor}
      >
        <div className={classes.navbarIcon}>
          <Typography className={classes.linkcolor}>ENG</Typography>
          <LanguageIcon />
        </div>
      </IconButton> */}
      {/* <IconButton
        style={{
          marginRight: 10,

          color: "#fff",
        }}
 
      >
        <PhoneIphoneIcon />
      </IconButton> */}
      {/* <IconButton
        style={{
          marginRight: 10,

          color: "#fff",
        }}
  
      >
        <SecurityIcon />
      </IconButton> */}
      {/* <IconButton
        style={{
          color: "#fff",
        }}
  
      >
        <NotificationsIcon />
      </IconButton> */}
      <SwipeableTemporaryDrawer />
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          {/* <NotificationList
            isLoading={isLoading}
            count={user?.unReadNotification}
            notificationList={user?.notificationList}
            popUp={open}
          /> */}
        </DialogContent>
      </Dialog>
    </>
  );
}
