import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MenuIcon from "@material-ui/icons/Menu";
import { Drawer, Grid, List, ListItem, Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ApiConfig from "src/config/APICongig";
import Axios from "axios";
import { toast } from "react-toastify";
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "block",
    "@media(max-width:1278px)": {
      display: "none",
    },
  },
  root1: {
    display: "none",
    "@media(max-width:1278px)": {
      display: "block",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-around",
  },
  linkButtonsDiv: {
    display: "flex",
    gap: "14px",
  },
  //   logo: {
  //     flexGrow: 1,
  //   },

  register: {
    background: "#B76722",
    borderRadius: "50px",
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "18px",
    color: "#FFFFFF",
    cursor: "pointer",
    padding: "10px 30px",
    textTransform: "none",
    minWidth: "140px",
    "&:hover": {
      color: '#fff',
      background: "#B76722",

    },
  },
  Login: {
    fontFamily: "Saira Semi Condensed",
    borderRadius: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15.2381px",
    lineHeight: "18px",
    color: "#FDFAFE",
    cursor: "pointer",
    // background: "linear-gradient(320deg, #1F7C99 0%, #15D7D7 100%)",
    // "-webkit-background-clip": "text",
    // "-webkit-text-fill-color": "transparent",
    // backgroundClip: "text",
    // textFillColor: "transparent",
    // textTransform: "none",
    padding: "12px 24px",
    border: '2px solid #FDFAFE',
    minWidth: "200px"
  },
  linkButton: {
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "18px",
    color: "#FDFAFE",
    cursor: "pointer",
  },
  linkButton1: {
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "18px",
    color: "#FFFFFF",
    cursor: "pointer",
  },

  registerBox: {
    display: "flex",
    gap: "20px",
  },
  drawerRight: {
    "& .MuiDrawer-paperAnchorRight": {
      background: "#161616",
      padding: "30px 20px",
    },
  },
  toolbarlogo: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function TopBar() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerContent, setOpenDrawerContent] = useState(false);
  const [logIn, setLogIn] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [walletAddres, setWalletAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
        localStorage.setItem("walletAddress", accounts[0]);
        ConnectWalletApi();
        // history.push("/login");
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      alert("Meta Mask not detected");
    }
  }
  const ConnectWalletApi = async (values) => {
    setIsLoading(true)
    const adsress = localStorage.getItem('walletAddress')

    try {

      const res = await Axios({
        method: "POST",
        url: ApiConfig.connectWallet,
        data: {
          walletAddress: adsress,
        },
      });
      console.log(res, "result")
      if (res.data.code === 200) {
        setIsLoading(false)
        toast.success("Login successfully");
        console.log("Login successfully");
        window.localStorage.setItem("userId", res.data.data._id);
        window.localStorage.setItem("token", res.data.data.token);
        history.push("/select-character")
      } else {
        toast.warn(res.data.responseMessage);
        setIsLoading(false)
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleDrawerOpen = () => {
    setOpenDrawerContent(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawerContent(false);
  };
  const inviteCode = "";
  const signupUrl = `${"/register"}?inviteCode=${inviteCode}`;
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      setLogIn(true);
    } else setLogIn(false);
  }, [token]);
  console.log(logIn, "logIn");

  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{ backgroundColor: "#1E1E1E", height: "93px" }}
        >
          <Toolbar className={classes.toolbar}>
            <Box>
              <img className={classes.logo} src="/images/logo.png" />
            </Box>
            <Box className={classes.linkButtonsDiv}>
              <Button className={classes.linkButton}>Home</Button>
              <Button className={classes.linkButton}>Nfts</Button>
              <Button className={classes.linkButton}>Marketplace</Button>
            </Box>
            {/* {logIn == true && ( */}
            <Box className={classes.registerBox}>
              {/* <Button
                className={classes.register}
                onClick={() => history.push("/login")}
              >
                Login
              </Button> */}
              <Button
                className={classes.register}
                onClick={requestAccount}
              >
                Connect Wallet {isLoading && <CircularProgress />}
              </Button>
            </Box>
            {/* )} */}
            {/* {logIn == false && (
              <Box className={classes.registerBox}>
                <Button
                  className={classes.register}
                // onClick={() => history.push(signupUrl)}
                >
                  Register
                </Button>
                <Button
                  className={classes.Login}
                // onClick={() => history.push("/login")}
                >
                  Login
                </Button>
              </Box>
            )} */}
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.root1}>
        <AppBar
          position="static"
          style={{ backgroundColor: "#1E1E1E", height: "93px" }}
        >
          <Toolbar className={classes.toolbarlogo}>
            <Box>
              <img className={classes.logo} src="/images/logo.png" />
            </Box>
            <Box>
              <MenuIcon onClick={handleDrawerOpen} />
            </Box>
          </Toolbar>
          <Drawer
            anchor="right"
            open={openDrawerContent}
            onClose={handleDrawerClose}
            className={classes.drawerRight}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.gridfour}
                style={{ width: "10rem" }}
              >
                <List>
                  <img className={classes.logo} src="/images/logo.png" />
                  <ListItem className={classes.linkButton1}>Home</ListItem>
                  <ListItem className={classes.linkButton1}>
                    Nfts
                  </ListItem>
                  <ListItem className={classes.linkButton1}>
                    Marketplace
                  </ListItem>


                  <ListItem
                    className={classes.linkButton1}
                    onClick={requestAccount}
                  >
                    Connect Wallet {isLoading && <CircularProgress />}
                  </ListItem>

                  {/* {logIn == false && (
                    <ListItem
                      style={{ maxWidth: '100px', minWidth: '100px' }}
                      className={classes.register}
                      onClick={() => history.push(signupUrl)}
                    >
                      Register
                    </ListItem>
                  )}
                  {logIn == false && (
                    <ListItem
                      style={{ maxWidth: '100px', minWidth: '100px' }}
                      className={classes.Login}
                      onClick={() => history.push("/login")}
                    >
                      Login
                    </ListItem>
                  )} */}
                </List>
              </Grid>
            </Grid>
          </Drawer>
        </AppBar>
      </div>
    </>
  );
}
